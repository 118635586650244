import * as React from "react";
import { Slot } from "@radix-ui/react-slot";
import { cva, type VariantProps } from "class-variance-authority";
import { cn } from "@/components/lib/css";
const buttonVariants = cva("inline-flex items-center justify-center gap-2 whitespace-nowrap rounded-button text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50 aria-disabled:pointer-events-none aria-disabled:opacity-50 [&_svg]:pointer-events-none [&_svg]:size-4 [&_svg]:shrink-0", {
  variants: {
    variant: {
      default: "bg-color-button-primary text-color-button-primary-inverted shadow hover:opacity-90",
      destructive: "bg-destructive text-destructive-foreground shadow-sm hover:bg-destructive/90",
      outline: "border-2 border-color-button-primary bg-transparent text-color-button-primary text-color-text-branded shadow-sm hover:bg-color-button-primary hover:text-color-button-primary-inverted",
      secondary: "bg-secondary text-secondary-foreground shadow-sm hover:bg-secondary/80",
      ghost: "hover:bg-accent hover:text-accent-foreground",
      gradient: "text-action-primary-text font-normal" + " [background:linear-gradient(90.05deg,_#8959ef_0.5%,_#595fef_47.98%,_#1ab4d7)]" + " hover:[background:linear-gradient(90.05deg,_#734BC9_0.5%,_#4B50C9_47.98%,_#1593B0)]",
      link: "text-color-text-branded underline-offset-4 hover:underline"
    },
    size: {
      default: "h-12 px-4 py-2 text-base",
      sm: "h-8 rounded-pill px-3 text-xs",
      lg: "h-10 rounded-containers px-8",
      icon: "h-9 w-9"
    }
  },
  defaultVariants: {
    variant: "default",
    size: "default"
  }
});
export interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement>, VariantProps<typeof buttonVariants> {
  asChild?: boolean;
}
const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(({
  className,
  variant,
  size,
  disabled,
  asChild = false,
  ...props
}, ref) => {
  const Comp = asChild ? Slot : "button";
  return <Comp className={cn(buttonVariants({
    variant,
    size,
    className
  }))} ref={ref} disabled={disabled} aria-disabled={disabled} {...props} />;
});
Button.displayName = "Button";
export { Button, buttonVariants };